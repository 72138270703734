/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import 'webrtc-adapter';
import { configStyle, positionDiv } from '../services/configStyle';
import Loader from "../components/loader";
import { enviarHologramaIdentificacion } from '../services/api.js';
import { isIOS, isMobileSafari, isMobile, withOrientationChange, isIPad13 } from 'react-device-detect';
import ConnectionClient from '../services/ConnectionClientWebRTC';
import { hayExcepcion, statusError, evento, endpointHolograma } from '../services/data';
import Header from "../components/headerNA";
import Ayuda from '../pages/ayuda';
import RecordRTC from 'recordrtc';

const bucket = process.env.REACT_APP_BUCKET;

let intervalo = null;

class CameraPhotoHologram extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        loading: false,
        uuid: null,
        dataUser: [],
        dataOtorgante: [],
        isStart: false,
        side: "front",
        proccessing: false,
        proccessingHolo: false,
        desktop: false,
        altura: 960,
        ancho: 720,
        showIdOverlay: false,
        apikey: "",
        hasResponse: false,
        isPassport: false,
        isHoloInstructions: true,
        showHoloAnimation: true,
        errorMsg: false,
        errorVideo: false,
        streamStarted: false,
        pathname: "",
        showHelp: false,
        capturing: false,
        suportMediaRecorder: true,
        loadingRecorder: true,
    }

    componentDidMount() {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }
        this.state.pathname = window.location.pathname;
        //console.log("bloque: " + bloqueo);
        if (!isMobile) {
            //console.log("es desktop");
            this.state.desktop = true;
            this.state.altura = 720;
            this.state.ancho = 960;
            this.setState({ showHoloAnimation: false })
            this.setState({ isHoloInstructions: false, isStart: true })
            var element = document.getElementById("video_wrt");
            element.classList.add("myVideo_rotate");
        } else {
            if (hayExcepcion()) {
                this.setState({ isHoloInstructions: false, isStart: true, showHoloAnimation: false })
            }
        }

        let divFooter = document.querySelector('#div-buttons-calibrate');
        divFooter.style.position = positionDiv(window.innerWidth, window.innerHeight);

        evento('Captura Holograma', 'Step', { status: 'OK' }, true);
    }

    start = (deviceFuncional) => {
        const that = this;
        if (window.stream) {
            //console.log("cerrando");
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }

        const constraints = {
            audio: false,
            video: (deviceFuncional) ? {
                deviceId: { exact: deviceFuncional },
            } : {
                facingMode: "environment",
            }
        };

        //console.log("Reconfiguracion",constraints);
        that.localStream = navigator.mediaDevices.getUserMedia(constraints)
            .catch((error) => {
                    if (error.name == "NotFoundError" || error.name == "DevicesNotFoundError") {
                        //required track is missing 
                        evento('Captura Holograma', 'User Media', { error:error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true);
                     } else if (error.name == "NotReadableError" || error.name == "TrackStartError") {
                        //webcam or mic are already in use 
                        evento('Captura Holograma', 'User Media', { error:error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true);
                     } else if (error.name == "OverconstrainedError" || error.name == "ConstraintNotSatisfiedError") {
                        //constraints can not be satisfied by avb. devices 
                        evento('Captura Holograma', 'User Media', { error:error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true);
                     } else if (error.name == "NotAllowedError" || error.name == "PermissionDeniedError") {
                        //permission denied in browser 
                        evento('Captura Holograma', 'User Media', { error:error.name, status: 'PERMISOS DENEGADOS' }, true);
                     } else if (error.name == "TypeError" || error.name == "TypeError") {
                        //empty constraints object 
                        evento('Captura Holograma', 'User Media', { error:error.name, status: 'CONSTRAINTS VACÍOS' }, true);
                     } else {
                        //other errors 
                        evento('Captura Holograma', 'User Media', { error:error.toString(), status: 'OTRO TIPO DE ERROR' }, true);
                     }
                    that.setState({ errorVideo: true })
                }
            );

        that.canvas = document.querySelector('canvas');
        that.width = 320;
        that.height = 0;
        that.video = document.querySelector('video');
        that.elemento = document.getElementById("cuadro");
        that.cuenta = document.getElementById("numeros");
        that.titulo = document.getElementById("head_shop");
        let component = that;
        that.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    //console.log("disponibles");
                    component.setState({ streamStarted: true, errorVideo: false });
                    if (window.MediaRecorder) {
                        //console.log('Soporta MediaRecorder');
                        let mymeType = null
                        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
                            mymeType = 'video/webm; codecs=vp9';
                        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
                            mymeType = 'video/webm; codecs=vp8';
                        }
                        let type = isIOS ? 'video/mp4' : mymeType;
                        const optionNormal = {
                            type: 'video',
                            mimeType: type,
                        };

                        component.recorder = new RecordRTC(mediaStream, optionNormal);
                        if(component.recorder) that.setState({ loadingRecorder: false });
                    } else {
                        //console.log('No soporta MediaRecorder');
                        component.setState({ suportMediaRecorder: false });
                    }
                } else {
                    //console.log("No disponibles");    
                    component.setState({ errorVideo: true })
                }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth * 1.5);
                component.canvas.setAttribute('height', component.video.videoHeight * 1.5);
            }, false);
        }).catch(e => {
            console.trace(e);
            that.setState({ errorVideo: true })
        })

    }

    cancelarCapturaIdentificacion = () => {
        evento('Captura Holograma', 'Click', { description: 'CANCELAR' }, true)
        this.props.history.push("/preparacion_identificacion");
    }

    enviarFotoHolograma = async () => {
        evento('Captura Holograma', 'WebRTC', { status: 'OK' }, true);
        const localVideo = document.getElementById('video_wrt');
        this.setState({ loading: true, hasResponse: false, capturing: true })
        const that = this
        const connectionClient = new ConnectionClient();
        let peerConnection = null;
        localVideo.autoplay = true;
        localVideo.muted = true;
        async function beforeAnswer(peerConnectionH) {
            that.localStream.then(function (mediaStream) {
                mediaStream.getTracks().forEach(track => peerConnectionH.addTrack(track, mediaStream));
            })
            peerConnection = peerConnectionH;
            let dataChannel = null;
            let interval = null;
            let timeoutt = null
            function onMessage({ data }) {
                const responseWRT = JSON.parse(data);
                //console.log(responseWRT);
                peerConnectionH.close()
                that.setState({ isHoloInstructions: false, proccessingHolo: false, capturing: false, showHoloAnimation: false });
                evento('Captura Holograma WebRTC', 'Success', { status: 'Ok' }, true);
                setTimeout(() => {
                    that.props.history.push('/identificacion');
                }, 200);
            }
            let intentos = 0
            function onDataChannel({ channel }) {
                if (channel.label !== 'ping-pong') {
                    return;
                }
                dataChannel = channel;
                dataChannel.addEventListener('message', onMessage);
                interval = setInterval(() => {
                    intentos++;
                    if (intentos === 5) {
                        localVideo.classList.add("blur_video");
                        that.setState({ proccessingHolo: true, loading: false })
                        dataChannel.send("finish_record");
                    } else if (intentos === 50) {
                        that.setState({ isHoloInstructions: false, proccessingHolo: false, capturing: false, showHoloAnimation: false });
                        evento('Captura Holograma WebRTC', 'Exception', { status: 'TIMEOUT' }, false);
                        setTimeout(() => {
                            that.props.history.push('/identificacion');
                        }, 200);
                        peerConnectionH.close()
                    }
                }, 1000);
                timeoutt = setTimeout(() => {
                    dataChannel.send(JSON.stringify({ uuid: that.state.uuid, side: that.state.side, bucket: bucket, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "HOLOGRAMA", isMobile: isMobile, isPassport: (that.state.isPassport).toString(), key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")) }));
                }, 500);
            }
            peerConnectionH.addEventListener('datachannel', onDataChannel);
            const { close } = peerConnectionH;
            peerConnectionH.close = function () {
                if (dataChannel) {
                    dataChannel.removeEventListener('message', onMessage);
                }
                if (interval) {
                    clearInterval(interval);
                }
                if (timeoutt) {
                    clearTimeout(timeoutt)
                }
                return close.apply(this, arguments);
            };
        }

        peerConnection = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
        window.peerConnection = peerConnection;
    }

    initRecordHologram = (side) => {
        //console.log("recording for hologram");
        this.state.suportMediaRecorder ? this.capturarHolograma() : this.enviarFotoHolograma();
        this.setState({ showHoloAnimation: true });
    }
    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                if(mediaStream)  mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {//console.log("Se quito componente video");
        });
    }
    statusF = () => {
        statusError("Error Captura Holograma", 'No se pudo capturar el holograma', "error")
    }

    permisoCamara = () => {
        let component = this;
        var deviceFuncional = "";
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
            for (let i = 0; i < devices.length; i++) {
                let device = devices[i];
                if (device.kind === 'videoinput') {
                    //console.log(device.label)
                    if ((device.label).includes("back")) {
                        if ((device.label).includes("0")) {
                            deviceFuncional = device.deviceId;
                            //console.log("deviceFuncional1", deviceFuncional);
                        }
                    }
                }
            }
            component.start(deviceFuncional);
        });
    }

    capturarHolograma = async () => {
        evento('Captura Holograma', 'MediaRecorder', { status: 'OK' }, true);
        const localVideo = document.getElementById('video_wrt');
        this.setState({ loading: true, hasResponse: false, capturing: true })
        const that = this
        localVideo.autoplay = true;
        localVideo.muted = true;
        that.recorder.startRecording();
        let intentos = 0;
        let blobvideo = null
        intervalo = setInterval(() => {
            intentos++;
            if (intentos === 6) {
                localVideo.classList.add("blur_video");
                that.setState({ proccessingHolo: true, loading: false })
                that.recorder.stopRecording(function () {
                    blobvideo = that.recorder.getBlob();
                    //that.downloadVideo(blobvideo);
                    that.enviarHolograma(blobvideo, isIOS)
                });
            } else if (intentos === 60) {
                that.setState({ isHoloInstructions: false, proccessingHolo: false, capturing: false })
                clearInterval(intervalo);
            }
        }, 1000);
    }

    enviarHolograma = (file, isIOS) => {
        endpointHolograma(file, isIOS);
        setTimeout(() => {
            clearInterval(intervalo);
            this.setState({ isHoloInstructions: false, proccessingHolo: false, capturing: false, showHoloAnimation: false });
            this.props.history.push('/identificacion');
        }, 1000);
    }

    downloadVideo = (blob) => {
        var video = document.createElement('video');
        video.src = URL.createObjectURL(blob);
        //console.log("time1", video.duration);
        video.onloadedmetadata = function (e) {
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "test.webm";
            a.click();
            window.URL.revokeObjectURL(url);
            //console.log("time2", video.duration);
        }
    }

    render() {
        const { isLandscape } = this.props;
        return (
            <>
                {((this.state.isHoloInstructions && !this.state.isStart && !this.state.showHelp) ?
                    <Fragment>
                        <div className="nu_gradient hollo_bx show_id_hold">

                            <Header ruta="/identificacion" show={() => this.setState({ showHelp: true })} />

                            <div className=" main_text_container">
                                <h1 className="fake_over">Calibra tu cámara</h1>
                                <img src="images/hollo_4.gif" width="100%" alt="" />
                                <p className="izquierdaText">1. Pon tu <b>identificación</b> al <b>centro</b>.
                                    <br />2. Presiona "<b>capturar</b>".
                                    <br />3. Mueve tu dispositivo <b>con lentitud de lado a lado</b>.<br />
                                </p>
                            </div>
                            <div className="div_bottom" id="div-buttons-calibrate">
                                <div className="action_buttons">
                                    <button type="button" onClick={(e) => {
                                        this.setState({ isStart: true, showIdOverlay: (this.state.desktop) ? true : false });
                                        this.permisoCamara();
                                        evento('Captura Holograma', 'Click', { description: 'CALIBRAR' }, true);
                                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__fadeIn animate__delay-1s">CALIBRAR</button>
                                    <button onClick={e => this.cancelarCapturaIdentificacion()} className="btn btn-primary forcewidth100 main_color animate__animated animate__fadeIn animate__delay-1s">CANCELAR</button>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    : "")
                }


                <div className="module_container overflow_hddn desk_id_capture" style={{ position: 'fixed' }}>
                    <div className="camera_capture_frame">
                        <video id="video_wrt" playsInline></video>
                        <canvas id="canvas_wrt_environment" hidden></canvas>
                    </div>
                    <div className="module_gradient_overlay"></div>
                    {(this.state.isHoloInstructions) && <><div className="module_title animate__animated animate__slideInDown">
                        <p><b>Calibrar</b> tu cámara</p>
                    </div>
                        <div>
                            <img className="ine_mask_cel animate__animated animate__fadeIn animate__delay-2s" src="images/id_mask_capture_hollo.svg" alt="" />
                        </div> </>}
                </div>
                {(this.state.showHoloAnimation && <div className="alert_instruction animate__animated animate__fadeIn animate__delay-1s alert_light">
                    <img src={process.env.PUBLIC_URL + 'images/hollo_animation.gif'} height="100" alt="" />
                </div>)}
                {(this.state.isHoloInstructions && !this.state.showHelp) ? <div className="module_buttons animate__animated animate__fadeIn animate__delay-3s"> <button type="button" onClick={() => this.initRecordHologram("front")} className={["btn btn-raised btn-primary forcewidth100 ", (this.state.capturing ? "gray_bg_color" : "main_bg_color"), (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessingHolo || this.state.loading || this.state.loadingRecorder}>{this.state.proccessingHolo || this.state.loading ? "CALIBRANDO ..." : "CAPTURAR Y CALIBRAR"}</button>
                 <button type="button" onClick={() => this.cancelarCapturaIdentificacion()} className={["btn btn-raised btn-primary forcewidth100 cancel_btn", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} >CANCELAR</button> </div> : ("")}
                {(this.state.proccessingHolo) && <Loader />}
                {((this.state.loading && !this.state.showHoloAnimation) ? <div className="sprite_stay_a animado"></div> : (""))}
                {(isLandscape) && (
                    <div className="rotate_device">
                        <div className="center_info_bx">
                            <img src="images/rotate_device.svg" height="100" alt="" />
                            <p>Por favor usa tu dispositivo en vertical<br />
                                <small>Gira tu dispositivo para continuar</small>
                            </p>
                        </div>
                    </div>
                )}
                {(this.state.errorMsg) &&
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Se ha detectado un bloqueo de red, verifica tu conexión de internet</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorMsg: false })
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>}
                {(this.state.errorVideo) &&
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Hemos detectado que la <b>cámara de tu dispositivo</b> está siendo usada por otra web en <b>alguna pestaña</b>. Por favor, asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorVideo: false })
                                this.permisoCamara();
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>}
                {this.state.showHelp && <Ayuda hide={() => this.setState({ showHelp: false })} />}
            </>
        );
    }


}
CameraPhotoHologram = withOrientationChange(CameraPhotoHologram)
export default withRouter(CameraPhotoHologram)