import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig, positionDiv } from '../services/configStyle'
import { actualizarEstado } from '../services/api.js';
import { status, statusError, evento } from '../services/data'
import Ayuda from './ayuda';

const Clausula = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const { ruta } = useParams();
    const [showHelp, setShowHelp] = useState(false);
    const divFooterRef = useRef(null);

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        status("clausulas_page", "Cláusula");
        let divFooter = divFooterRef.current;
        divFooter.style.position = positionDiv(window.innerWidth, window.innerHeight);
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])


    const statusE = () => {
        //console.log("entre a status cancelar");
        let data = "Cancelado";
        statusError("Cláusula", data, "cancelado");
        evento('Clausula', 'Click', { description:  'CANCELAR' }, true);
        setTimeout(() => {
            window.close()
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`))
        }, 300);
    }

    return (
        <Fragment>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => setShowCancel(false)} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-secondary modal_btn_w">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="clausula" show={() => setShowHelp(true)}/>
                <div className="main_text_container">
                    <h1 className="animate__animated">{(obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_2", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_2")}</h1>
                    <p className="animate__animated">Lee con atención el documento y aceptaló <b>si estás de acuerdo</b> con lo estipulado.</p>
                </div>
                <div className="main_icon_container animate__animated">
                    <img src="../images/bnw/doc_check.svg" alt="" />
                </div>
                <div className="div_bottom" ref={divFooterRef}>
                    <div className="action_buttons">
                        <Link to="/validacion_documento" className="btn btn-raised btn-primary forcewidth100 main_bg_color">LEER DOCUMENTO</Link>
                        <button onClick={e => setShowCancel(true)} className="btn btn-primary forcewidth100 main_color">
                            CANCELAR</button>
                    </div>
                    <Footer />
                </div>
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </Fragment>
    )

}

export default Clausula