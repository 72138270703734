import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import { actualizarEstado, enviarRenapo, enviarIne } from '../../services/api';
import { isAndroid } from 'react-device-detect';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { hayExcepcion, agregarInfo, status, statusData, esEditable, formatoFechaOcr, formatoFechaCuatroDigitos, validarCampo, validarValoresFormulario, evento, statusError } from '../../services/data'
import Info from '../../components/encuentra_info';


let FormularioIne = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false);
    const [focusVigencia, setFocusVigencia] = useState(false);
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [esCLaboral, setCLaboral] = useState(true);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({
        nombres: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        fechaNacimiento: '',
        vigencia: '',
        curp: ''
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [esVigente, setEsVigente] = useState(true)
    const [fechaNacimiento, setfechaNacimiento] = useState("");
    const [focusApellidoPaterno, setFocusApellidoPaterno] = useState(false);
    const [focusApellidoMaterno, setFocusApellidoMaterno] = useState(false);
    const [sendForm, setSendForm] = useState(false);
    
    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            setDataOCRFront(ocrFront);
            if (ocrFront.nombres)
                setInputsValue(inputsValue => ({ ...inputsValue, ["nombres"]: ocrFront.nombres || '' }));
            if (ocrFront.apellidoPaterno)
                setInputsValue(inputsValue => ({ ...inputsValue, ["apellidoPaterno"]: ocrFront.apellidoPaterno || '' }));
            if (ocrFront.apellidoMaterno)
                setInputsValue(inputsValue => ({ ...inputsValue, ["apellidoMaterno"]: ocrFront.apellidoMaterno || '' }));
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                }
                setfechaNacimiento(ocrFront.fechaNacimiento)
            }
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrFront.curp).trim() }));
            let vigenciaC = ''
            if (ocrFront.vigencia)
                vigenciaC = "20" + ocrFront.vigencia.substr(-2);

            setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
        }

        if (localStorage.getItem("apikeyOtorgante") === "50e6a1b5-fc07-459d-a78a-de4a3cad810b") {
            setCLaboral(false)
        }

        let dataOCRBackLS = localStorage.getItem("ocrBack");

        if (dataOCRBackLS != null) {
            setDataOCRBack(JSON.parse(dataOCRBackLS));
            const ocrBack = JSON.parse(dataOCRBackLS);
            let vigenciaC = '';

            if (!ocrFront.ineVigencia) {
                if (ocrBack.ineVigencia) {
                    vigenciaC = ocrBack.ineVigencia;
                    let formatosP = ['DD/MM/YYYY', 'DD/MM/YY'];
                    if (moment(vigenciaC, formatosP, true).isValid()) {
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                    }
                } else {
                    if (ocrFront.vigencia) {
                        if (vigenciaC) {
                            setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                        } else {
                            let vi = ""
                            if (ocrFront.vigencia.includes(' '))
                                vi = ocrFront.vigencia.replaceAll(' ', '/');
                            else
                                vi = ocrFront.vigencia;
                            setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vi }));
                        }
                    } else {
                        if (ocrFront.vigencia)
                            vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                    }
                }
            }
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let value = event.target.value.trim();
        let validacion = validarCampo(value, event.target.name);
        if (event.target.name === "fechaNacimiento") {
            setfechaNacimiento(value)
        }
        if (validacion.correcto) {
            if (event.target.name === "curp") {
                setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value.toUpperCase()}));
            } else {
                setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            }
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }

    };

    useEffect(() => {
        //console.log("inputsValueM",inputsValue);
    }, [inputsValue]);

    const validarFormulario = (event) => {
        if (event) event.persist();event.preventDefault();event.stopPropagation();
        setSendForm(true);
        //console.log("valores",valores);
        let continuar = validarValoresFormulario(inputsValue);
        if (continuar.correcto) {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'OK' }, true);
            /* if (obtenerValorConfig(dataOtorgante, 'ENABLE_RENAPO', "true") === "true") {
                enviarRenapo(inputsValue.curp).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log("Error RENAPO: " + error);
                });
            }

            if (obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "true") === "true" && esIne) {
                enviarIne(inputsValue.cic, inputsValue.clave, inputsValue.numeroEmision).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log("Error INE: " + error);
                });
            } */

            let infoFormulario = []
            let fechaActual = inputsValue.vigencia
            agregarInfo(dataUser, { description: "nombres", value: inputsValue.nombres });
            infoFormulario.push({ description: "nombres", value: inputsValue.nombres })
            agregarInfo(dataUser, { description: 'apellidoPaterno', value: inputsValue.apellidoPaterno });
            infoFormulario.push({ description: "apellidoPaterno", value: inputsValue.apellidoPaterno })
            agregarInfo(dataUser, { description: "apellidoMaterno", value: inputsValue.apellidoMaterno });
            infoFormulario.push({ description: "apellidoMaterno", value: inputsValue.apellidoMaterno });
            let nombreCompleto = `${inputsValue.nombres} ${inputsValue.apellidoPaterno} ${inputsValue.apellidoMaterno}`
            agregarInfo(dataUser, { description: "nombreCompleto", value: nombreCompleto });
            infoFormulario.push({ description: "nombreCompleto", value: nombreCompleto });
            let fechaNacimiento = document.getElementById("fechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: fechaNacimiento });
            infoFormulario.push({ description: "nacimiento", value: fechaNacimiento })
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "curp", value: inputsValue.curp })
            let today = new Date();
            let year = today.getFullYear();
            if (Number(fechaActual) >= year) {
                infoFormulario.push({ description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) })
                agregarInfo(dataUser, { description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) });
                fechaActual = "20" + inputsValue.vigencia.substr(-2) + "/12/31";
                let fechaV = new Date(fechaActual);
                if (fechaV >= Date.now()) {
                    console.log("Vigencia válida");
                    sendData(infoFormulario);
                    setTimeout(() => {
                        if (hayExcepcion()) {
                            history.push('/clausula');
                        } else {
                            history.push('/identificacion_edit')
                        }
                    }, 300);
                } else {
                    console.log("Vigencia invalida");
                    setEsVigente(false)
                }
            } else {
                console.log("Vigencia invalida");
                setEsVigente(false)
            }
        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false);
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }));
            setSendForm(false);
        }
    }

    const terminarFlujo = async () => {
        //console.log("entre a status cancelar");
        let data = "Fecha de vigencia no validá";
        statusError("datos_personales_ocr", data, "cancelado");
        evento('Datos personales OCR', 'Click', { description: 'TERMINAR', status: data }, true);
        if(!window.opener){
            setTimeout(() => {
                window.close()
                history.push("/" + apiKey);
            }, 300);
        }
    }


    return (
        <Fragment>
            <form id="FormularioIne">
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombres !== "") ? "is-focused" : ""), inputsErrors.nombres && 'error'].join(" ")}>
                        <label htmlFor="nombres" className="bmd-label-floating">Nombre(s):</label>
                        <input type="text" className="form-control none_border" id="nombres" name="nombres" defaultValue={inputsValue.nombres} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombres === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.nombres && (
                            <span id="ht-nombres" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombres}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusApellidoPaterno || inputsValue.apellidoPaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoPaterno && 'error'].join(" ")}>
                        <label htmlFor="apellidoPaterno" className="bmd-label-floating">Apellido Paterno:</label>
                        <input type="text" className="form-control none_border" id="apellidoPaterno" name="apellidoPaterno" defaultValue={inputsValue.apellidoPaterno} onChange={handleChange} onFocus={e => {
                            setFocusApellidoPaterno(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombres === "") {
                                setFocusApellidoPaterno(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.apellidoPaterno && (
                            <span id="ht-apellidoPaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoPaterno}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusApellidoMaterno || inputsValue.apellidoMaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoMaterno && 'error'].join(" ")}>
                        <label htmlFor="apellidoMaterno" className="bmd-label-floating">Apellido Materno:</label>
                        <input type="text" className="form-control none_border" id="apellidoMaterno" name="apellidoMaterno" defaultValue={inputsValue.apellidoMaterno} onChange={handleChange} onFocus={e => {
                            setFocusApellidoMaterno(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.apellidoMaterno === "") {
                                setFocusApellidoMaterno(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.apellidoMaterno && (
                            <span id="ht-apellidoMaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoMaterno}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento) ? "is-focused" : ""), inputsErrors.fechaNacimiento && 'error'].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control none_border" id="fechaNacimiento" name="fechaNacimiento" defaultValue={inputsValue.fechaNacimiento} value={fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                        }
                        } onBlur={e => {
                            if (inputsValue.fechaNacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigencia !== "") ? "is-focused" : ""), inputsErrors.vigencia && 'error'].join(" ")}>
                        <label htmlFor="vigencia" className="bmd-label-floating">Vencimiento identificación:</label>
                        <input type="text" className="form-control none_border" id="vigencia" name="vigencia" maxLength="4" defaultValue={inputsValue.vigencia} onChange={handleChange} onFocus={e => {
                            setFocusVigencia(true)
                        }} onBlur={e => {
                            if (inputsValue.vigencia === "") {
                                setFocusVigencia(false)
                            }
                        }} />
                        {inputsErrors.vigencia && (
                            <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigencia}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                        <label htmlFor="curp" className="bmd-label-floating">CURP</label>
                        <input type="text" className="form-control uppercase none_border" id="curp" name="curp" defaultValue={inputsValue.curp} maxLength="19" onChange={handleChange} onFocus={e => {
                            setFocusCurp(true)
                        }} onBlur={e => {
                            if (inputsValue.curp === "") {
                                setFocusCurp(false)
                            }
                        }} />
                        {inputsErrors.curp && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                        )}
                    </div>
                </div>
                {(!esVigente) && (
                    <div className="err_finale  animated slideInUp">
                        <div className="center_checks">
                            <h5>Identificación no valida </h5>
                            <p>La <b>vigencia</b> de tu identificación <b>{inputsValue.vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <button className="btn btn-secondary forcewidth100" onClick={terminarFlujo}>TERMINAR</button>
                                {/* <Link to={{
                                    pathname: "/" + apiKey
                                }} className="btn btn-secondary forcewidth100" onClick={e => evento('Datos personales OCR', 'Click', { description: 'TERMINAR' }, true)}>TERMINAR</Link> */}
                                <Link to={{
                                    pathname: "/identificacion",
                                    state: { passport: esPassport }
                                }} className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={e => evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true)}>CAPTURAR NUEVAMENTE</Link>
                            </div>
                        </div>
                    </div>)}
                <div className="action_buttons animate__animated">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)} disabled={sendForm}>MIS DATOS SON CORRECTOS</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color" onClick={e => evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true)}>CAPTURAR NUEVAMENTE</Link>
                </div>
            </form>
        </Fragment>
    )
}

export default FormularioIne