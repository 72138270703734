import React, { useState, useEffect, Fragment } from "react";
import {useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, } from '../services/configStyle';
const ValidarMovil = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [uuid, setUuid] = useState("")
    const [apiKey, setApiKey] = useState('')
    const [movil, setMovil] = useState('')
    const [focusMovil, setFocusMovil] = useState(false)
    const [changeStyleValue, setChangeStyleValue] = useState(false)
    //const [inputActive, setInputActive] = useState(false)
    const [showCancel, setShowCancel] = useState(false)

    useEffect(() => {
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
            configStyle(JSON.parse(dataOtorganteLS))
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
            //console.log(uuid);
        }
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const changeStyle = () => {
        setChangeStyleValue(true)
        //console.log(changeStyleValue);
    }

    const enableFullScreenSpace = () => {
        setChangeStyleValue(false)
        //console.log(changeStyleValue);
    }

    return (
        <Fragment>
        {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso. Ningún dato será guardado y perderás el avance. Esta acción no podrá deshacerse. <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => setShowCancel(false)} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => history.push('/' + apiKey)} className="btn btn-raised btn-secondary modal_btn_w">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}

        <div className="main_gradient">
            <Header ruta="validar_movil" />
            <div class="main_text_container">
                <h1>Aceptación de consulta de historial crediticio</h1>
                <p>Haz <b>completado</b> el proceso, desafortunadamente debemos <b>validar</b> con un asesor por favor ingresa tu <b>número móvil</b> para comunicarnos contigo. </p>
            </div>
            <div class="ocr_data_display">
                <form>
                  <div class={["form-group", "bmd-form-group", (focusMovil ? "is-focused" : "")].join(" ")}>
                    <label for="exampleInputEmail1" class="bmd-label-floating">Número móvil:</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" defaultValue={movil} onChange={e => setMovil(e.target.value)} onFocus={e => {
                                setFocusMovil(true)
                                changeStyle()
                                //setInputActive(true)
                            }} onBlur={e => {
                                enableFullScreenSpace()
                                if (movil === "") {
                                    setFocusMovil(false)
                                }
                                //setInputActive(false)
                            }} />
                  </div>
                </form>
            </div>
            <div class="action_buttons noscroll_screen">
                <button type="button" class="btn btn-primary forcewidth100 main_color" onClick={e => setShowCancel(true)} >CANCELAR</button>
                <button type="button" disabled={!movil} class={(movil) ? "btn btn-raised btn-primary forcewidth100 main_bg_color" : "btn btn-raised btn-secondary forcewidth100"}>CONTINUAR</button>
            </div>
            <Footer />
        </div>
        </Fragment>
    )

}

export default ValidarMovil