import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { configStyle,obtenerValorConfig } from '../services/configStyle';
import Header from "../components/header";
import Footer from "../components/footer";
import { obtenerParametrosOtorgantePK } from '../services/api.js';
import { isChrome, isEdge, isMobile, isFirefox, isIOS, isSafari } from 'react-device-detect';

const PdfToken = () => {
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [fechaVigencia, setFechaVigencia] = useState("");
    const history = useHistory();
    const [browserDnotSupported, setNotSupported] = useState(false);
    const [browsernotSupportediOS, setNotSupportediOS] = useState(false);
    const [errorCarga, seterrorCarga] = useState(false);

    useEffect(() => {
        configStyle(dataOtorgante)
        localStorage.setItem("dataOtorgante", JSON.stringify(dataOtorgante));
        let logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""));
        ////console.log("logo", logo);
        document.getElementById("imagenLogo").src = logo;
    }, [dataOtorgante])

    useEffect(() => {
        if (!isMobile && !isEdge && !isChrome) {
            //console.log("desktop not supported browsers");
            setNotSupported(true);
        } else if (isMobile && isIOS && !isSafari) {
            //console.log("mobile not supported browsers in iOS");
            setNotSupportediOS(true);
        } else if (isMobile && !isIOS && (!isFirefox  && !isChrome)) {
            //console.log("Android, not supported browser");
            setNotSupported(true);
        }
    }, [])

    const obtenerParametros = async (apiKey) => {
        obtenerParametrosOtorgantePK(apiKey, apiKey).then((data) => {
            if (data.status === 200) {
                setDataOtorgante(data.data.payload);
            }else{
                seterrorCarga(true)
            }
        }).catch(() => {
            //console.log("algun error");
            seterrorCarga(true)
        });
    };

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    useEffect(() => {
        localStorage.clear();
        localStorage.setItem("uuidUser", query.get("uuid"));
        localStorage.setItem("idOtorgante", query.get("idOtorgante"));
        localStorage.setItem("fechavigencia", query.get("fecha"));
        localStorage.setItem("uuidTrx", query.get("uuidTrx"));
        setFechaVigencia(query.get("fecha"));
        obtenerParametros(query.get("idOtorgante"));
        setTimeout(() => {
            if (Date.now() > query.get("fecha")) {
                history.push("/pdf_token_error");
            }
        }, 500);
    }, []);

    return (
    <div className="main_gradient">
        <Header ruta={`pdf_token`} />
        {(errorCarga) ? 
                <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Verifica tu <b>conexión de internet</b> y vuelve a intentar</p>
                            <button type="button" onClick={(e) => {
                                seterrorCarga(false); window.location.reload();
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                :
                ""
            }
        <div className="main_text_container">
            <h1 className="animate__animated animate__fadeIn animate__delay-1s">Resultado de proceso</h1>
            <p className="animate__animated animate__fadeIn animate__delay-2s">
                Para ver o descargar el resultado de tu proceso <b>asegúrate</b> que tu dispositivo tenga <b>acceso a la cámara</b> y que te encuentres en un <b>lugar silencioso</b>:
                    <br /><br />
                <small>*<b>Respalda</b> tu documento, solo se puede <b>consultar una vez</b>.</small><br /><br />

                <small>Vencimiento: <b>{new Intl.DateTimeFormat("es-MX", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit"
                }).format(new Date(Number(fechaVigencia)))}</b></small>
            </p>
        </div>
        <div className="action_buttons noscroll_screen">
            <Link to="/pdf_video_token" className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__fadeIn animate__delay-3s" >CONTINUAR</Link>
        </div>
        {((browserDnotSupported) ? <div className="browser_not_comp show_me_bx">
            <div className="top_bx">
                <img src="images/browser_alert_icon.svg" alt="" />  
                <h4>Lo sentimos tu navegador actual no es <br />                        
                compatible con nuestra plataforma.</h4>
                <div className="divider"></div>
            </div>
            <div className="bottom_bx">
                <p><small>Te sugerimos utilizar o descargar alguna de estas opciones.</small></p>                    
                <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                    <img src="images/browser_chrome.png" width="96" alt="" />
                    <a href="https://www.google.com/chrome/">Chrome</a>
                    <small>Google</small>
                </div>
                <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                    <img src="images/browser_edge.png" width="96" alt="" />
                    <a href="https://www.microsoft.com/en-us/edge">Edge</a>
                    <small>Microsoft</small>
                </div>
            </div>
        </div> : (""))}
        {( (browsernotSupportediOS) ? <div className="browser_not_comp show_me_bx"> 
            <div className="top_bx">
                <img src="images/browser_alert_icon.svg" alt="" />
                <h4>Lo sentimos tu navegador actual no es <br />
                compatible con nuestra plataforma.</h4>
                <div className="divider"></div>
            </div>
            <div className="bottom_bx">
                <p><small>Te sugerimos utilizar o descargar alguna de estas opciones.</small></p>
                <div className="browser_bx ios_browser">
                    <img src="images/browser_ios.png" width="96" alt="" />
                    <a href="#!">Safari</a>
                    <small>Apple</small>
                </div>
            </div>        
        </div> : (""))}
        <Footer />
    </div>)
}

export default PdfToken