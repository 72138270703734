'use strict';
const DefaultRTCPeerConnection = require('wrtc').RTCPeerConnection;
const {
  RTCSessionDescription
} = require('wrtc');

const TIME_TO_HOST_CANDIDATES = 3000; 

const HOST_WEBRTC = process.env.REACT_APP_WEBRTC;
// const HOST_WEBRTC = "https://wrtc.dev.devdicio.net:3001"; 
// const HOST_WEBRTC = "https://wrtc-celd.proddicio.net:3001"; 

class ConnectionClientWebRTC {
  constructor(options = {}) {
    options = {
      RTCPeerConnection: DefaultRTCPeerConnection,
      clearTimeout,
      host: '',
      prefix: '.',
      setTimeout,
      timeToHostCandidates: TIME_TO_HOST_CANDIDATES,
      ...options
    };

    const {
      RTCPeerConnection
    } = options;

    this.createConnection = async (options2 = {}) => {
      options2 = {
        beforeAnswer() {},
        stereo: false,
        ...options2
      };

      const {
        beforeAnswer,
        stereo
      } = options2;

      options=options2;

      const response1 = await fetch(`${HOST_WEBRTC}/record/connections`, {
        method: 'POST'
      });

      const remotePeerConnection = await response1.json();
      const {
        id
      } = remotePeerConnection;

      const localPeerConnection = new RTCPeerConnection({
        sdpSemantics: 'unified-plan'
      });


      localPeerConnection.close = function () {
        fetch(`${HOST_WEBRTC}/record/connections/${id}`, {
          method: 'delete'
        }).catch(() => {});
        return RTCPeerConnection.prototype.close.apply(this, arguments);
      };

      try {
        await localPeerConnection.setRemoteDescription(remotePeerConnection.localDescription);

        await beforeAnswer(localPeerConnection);

        const originalAnswer = await localPeerConnection.createAnswer();
        const updatedAnswer = new RTCSessionDescription({
          type: 'answer',
          sdp: stereo ? enableStereoOpus(originalAnswer.sdp) : originalAnswer.sdp
        });
        await localPeerConnection.setLocalDescription(updatedAnswer);

        await fetch(`${HOST_WEBRTC}/record/connections/${id}/remote-description`, {
          method: 'POST',
          body: JSON.stringify(localPeerConnection.localDescription),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        return localPeerConnection;
      } catch (error) {
        localPeerConnection.close();
        throw error;
      }
    };
  }
}

function enableStereoOpus(sdp) {
  return sdp.replace(/a=fmtp:111/, 'a=fmtp:111 stereo=1\r\na=fmtp:111');
}

export default ConnectionClientWebRTC;